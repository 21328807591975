@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk5hkaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4n.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4n.ttf) format('truetype');
}
@font-face {
  font-family: 'icon font';
  src: url('/static/fonts/icon-font.eot');
  src: url('/static/fonts/icon-font.eot?#iefix') format('embedded-opentype'), url('/static/fonts/icon-font.woff') format('woff'), url('/static/fonts/icon-font.ttf') format('truetype'), url('/static/fonts/icon-font.svg#icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class*='icon-']:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.iconic:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-aeroplane:before {
  content: "\E900";
}
.icon-aeroplane:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-arr-right:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-arr-right:before {
  content: "\E901";
}
.icon-bed:before {
  content: "\E902";
}
.icon-bed:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-coins:before {
  content: "\E903";
}
.icon-coins:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-construction:before {
  content: "\E904";
}
.icon-construction:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-currency:before {
  content: "\E905";
}
.icon-currency:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-delivery-truck:before {
  content: "\E906";
}
.icon-delivery-truck:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-devices:before {
  content: "\E907";
}
.icon-devices:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-draw:before {
  content: "\E908";
}
.icon-draw:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-exam:before {
  content: "\E909";
}
.icon-exam:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-facebook-logo:before {
  content: "\E90A";
}
.icon-facebook-logo:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-facebook:before {
  content: "\E90B";
}
.icon-facebook:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-faq-arr:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-faq-arr:before {
  content: "\E90C";
}
.icon-file:before {
  content: "\E90D";
}
.icon-file:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-google-plus:before {
  content: "\E90E";
}
.icon-google-plus:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-guarantee-certificate:before {
  content: "\E90F";
}
.icon-guarantee-certificate:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-headphones:before {
  content: "\E910";
}
.icon-headphones:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-helmet:before {
  content: "\E911";
}
.icon-helmet:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-instagram:before {
  content: "\E912";
}
.icon-instagram:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-invitation:before {
  content: "\E913";
}
.icon-invitation:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-like:before {
  content: "\E914";
}
.icon-like:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-locked:before {
  content: "\E915";
}
.icon-locked:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-magician-hat:before {
  content: "\E916";
}
.icon-magician-hat:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-odnoklassniki-logo:before {
  content: "\E917";
}
.icon-odnoklassniki-logo:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-round-done-button:before {
  content: "\E918";
}
.icon-round-done-button:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-social:before {
  content: "\E919";
}
.icon-social:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-tick:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-tick:before {
  content: "\E91A";
}
.icon-vip:before {
  content: "\E91B";
}
.icon-vip:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-vk:before {
  content: "\E91C";
}
.icon-vk:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-warning:before {
  content: "\E91D";
}
.icon-warning:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-yplay:before {
  content: "\E91E";
}
.icon-yplay:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-yyadd:before {
  content: "\E91F";
}
.icon-yyadd:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-yycancel:before {
  content: "\E920";
}
.icon-yycancel:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-yycheck:before {
  content: "\E921";
}
.icon-yycheck:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-yyclose:before {
  content: "\E922";
}
.icon-yyclose:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-yyfile:before {
  content: "\E923";
}
.icon-yyfile:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-yyupload:before {
  content: "\E924";
}
.icon-yyupload:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-yyycalendar:before {
  content: "\E925";
}
.icon-yyycalendar:before:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.header {
  position: relative;
}
.carousel {
  z-index: 2;
}
.carousel .h2 {
  font-size: 30px;
  margin-bottom: 45px;
  font-weight: 400;
  line-height: 1.38;
}
.carousel .btn-primary {
  font-size: 18px;
}
.carousel-item {
  height: 700px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.carousel-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(2, 2, 2, 0.6);
}
.carousel-item--small {
  height: 290px;
}
.carousel-item--small .h1 {
  margin-top: 70px;
  margin-bottom: 5px!important;
}
.carousel-item--small .h2 {
  margin-bottom: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 40px;
  height: 40px;
}
.carousel-caption {
  bottom: 180px;
}
.carousel-caption--inner {
  bottom: 50%;
  transform: translateY(50%);
}
.carousel-scroll-btn {
  width: 48px;
  height: 48px;
  background: rgba(196, 196, 196, 0.5);
  color: #fff;
  transform: rotate(90deg);
  position: absolute;
  display: block;
  left: 50%;
  margin-left: -24px;
  bottom: 110px;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  z-index: 1;
  opacity: 0.7;
  transition: all 0.3s ease;
}
.carousel-scroll-btn:hover {
  opacity: 1;
}
.carousel-scroll-btn:before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.carousel-alerts {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.52);
  font-size: 18px;
  font-weight: 300;
  color: #fff;
  text-align: center;
}
.carousel-alerts .row {
  padding: 17px 0;
}
.carousel-alerts__item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel-alerts__item:hover .carousel-alerts__text {
  text-decoration: underline;
}
.carousel-alerts__icon {
  font-size: 0;
}
.carousel-alerts__icon:before {
  font-size: 30px;
}
.carousel-alerts__icon.icon-warning {
  color: #EB5757;
}
.carousel-alerts__icon.info {
  color: #00B0FE;
  transform: rotate(180deg);
}
.carousel-alerts__text {
  margin-left: 15px;
  text-align: left;
  color: #fff;
}
.nav-wr {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
}
.navbar {
  padding: 24px 0;
}
.navbar-nav {
  flex-grow: 1;
  position: relative;
  max-width: 580px;
  padding-top: 0;
}
.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  position: absolute;
  top: 20px;
  right: 10px;
}
.navbar-nav.sm-collapsible .sub-arrow {
  left: 10px !important;
}
.navbar-right {
  margin-left: 1rem;
}
.navbar-right br {
  display: none;
}
@media screen and (max-width: 1199px) {
  .navbar-right br {
    display: inline;
  }
  .navbar-right .dropdown-lang {
    margin-top: 15px;
  }
}
.navbar .dropdown-menu {
  background: rgba(0, 0, 0, 0.7);
}
.navbar .dropdown-menu .dropdown-item {
  color: white;
  padding: 8px 30px;
}
.navbar .dropdown-menu .dropdown-item:hover {
  color: #212529;
}
@media screen and (max-width: 767px) {
  .navbar .dropdown-menu {
    background: none;
    border: none;
    text-align: right;
  }
}
.dropdown-lang {
  margin-left: 25px;
  cursor: pointer;
}
.dropdown-lang .dropdown-toggle {
  width: 24px;
  height: 24px;
}
.dropdown-lang .dropdown-toggle .dropdown-lang__img {
  right: 0;
}
.dropdown-lang .dropdown-toggle:after {
  display: none;
}
.dropdown-lang .dropdown-menu-right {
  right: -10px;
  left: auto;
  padding-top: 0;
  padding-bottom: 0;
  top: -5px;
  min-width: 0;
  background: rgba(255, 255, 255, 0.95);
  border: 0;
}
.dropdown-lang__item {
  display: block;
  position: relative;
  text-align: right;
  padding: 10px 30px;
}
.dropdown-lang__item:hover {
  background: rgba(0, 0, 0, 0.21);
}
.dropdown-lang__item:first-child {
  padding-top: 15px;
}
.dropdown-lang__item:last-child {
  padding-bottom: 15px;
}
.dropdown-lang__img {
  width: 24px;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -10px;
}
.dropdown-lang__text {
  display: block;
  padding-right: 30px;
  font-size: 14px;
  font-weight: 300;
  color: #4F4F4F;
}
.dropdown-login {
  cursor: pointer;
}
.dropdown-login .dropdown-toggle:after {
  display: none;
}
.dropdown-login .dropdown-menu-right {
  right: -10px;
  left: auto;
  padding-top: 0;
  padding-bottom: 0;
  top: -5px;
  background: rgba(255, 255, 255, 0.95);
  border: 0;
}
.dropdown-login__item {
  display: block;
  position: relative;
  text-align: right;
  padding: 10px 30px;
  font-size: 14px;
  font-weight: 300;
  color: #4F4F4F;
}
.dropdown-login__item:hover {
  background: rgba(0, 0, 0, 0.21);
  color: #4F4F4F;
}
.dropdown-login__item:first-child {
  padding-top: 15px;
}
.dropdown-login__item:last-child {
  padding-bottom: 15px;
}
.navbar-user {
  align-items: center;
  color: rgba(255, 255, 255, 0.8);
}
.navbar-user:hover {
  color: #ffffff;
}
.navbar-user .icon-social {
  font-size: 0;
}
.navbar-user .icon-social:before {
  font-size: 26px;
}
.navbar-user__text {
  font-size: 18px;
  margin-left: 14px;
}
.nav-item {
  text-align: center;
  padding: 0 20px !important;
  position: relative;
  min-width: auto !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: #ffffff;
}
@media screen and (max-width: 1199px) {
  .navbar-nav {
    max-width: 550px;
    flex-grow: 0;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .nav-item {
    width: auto;
  }
  .nav-item .nav-link {
    position: relative;
  }
  .nav-item .nav-link:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: none;
  }
  .nav-item.active .nav-link:after {
    display: block;
  }
  .navbar-nav hr {
    display: none!important;
  }
}
@media screen and (max-width: 991px) {
  .navbar-nav {
    max-width: auto;
    display: block;
  }
  .navbar-collapse {
    border-radius: 0;
    padding: 10px 15px;
    text-align: center;
    margin: 10px -15px 0;
    max-height: 100vh;
    overflow-y: scroll;
  }
  .nav-item .nav-link {
    text-align: right;
    display: block;
    padding-left: 0!important;
    padding-right: 0!important;
    font-weight: 300;
  }
  .nav-item .nav-link:after {
    display: none!important;
  }
  .nav-item.active > .nav-link {
    font-weight: 600!important;
  }
  .navbar-right {
    margin-left: 0;
    padding: 30px 20px 10px;
    text-align: right;
    margin-top: 20px;
    border-top: #96cefb solid 1px;
  }
  .nav-wr {
    background: #00b0fef0;
    position: fixed;
    z-index: 100;
  }
  .nav-wr .nav-link {
    font-weight: normal;
  }
}
@media screen and (max-width: 767px) {
  .carousel .h2 {
    font-size: 18px;
    margin-bottom: 60px;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 24px;
    height: 24px;
  }
  .carousel .btn-primary {
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .carousel-caption {
    bottom: 50%;
    transform: translateY(50%);
  }
  .carousel-scroll-btn {
    bottom: 100px;
  }
  .carousel-alerts__text {
    font-size: 15px;
  }
  .carousel-item--small .h1 {
    margin-top: 140px;
  }
}
@media screen and (max-width: 575px) {
  .carousel-alerts .row {
    padding: 5px 0;
  }
  .carousel-alerts__item {
    padding: 5px 0;
  }
  .carousel-alerts__text {
    margin-left: 10px;
  }
  .carousel-alerts__icon:before {
    font-size: 24px;
  }
}
@media screen and (max-width: 380px) {
  .carousel-alerts__item {
    justify-content: flex-start;
  }
}
.whatsapp-block {
  background-color: rgba(0, 0, 0, 0.08);
  color: #000;
  font-size: 14px;
  line-height: 1.35;
  padding: 40px;
  font-weight: 300;
}
.whatsapp-block p a {
  color: #666666;
  text-decoration: underline;
}
.whatsapp-block p a:hover {
  color: #333333;
}
.whatsapp-block-title {
  font-size: 18px;
  color: #666666;
  line-height: 2;
  font-weight: bold;
  padding: 30px 30px 0 0;
  text-align: center;
}
.whatsapp-block-qr {
  margin-top: 0;
  text-align: center;
  font-weight: 300;
}
.whatsapp-block-qr p {
  font-weight: bold;
}
.whatsapp-block-qr img {
  width: 100px;
  height: 100px;
}
.whatsapp-block-qr span {
  margin-top: 15px;
  display: block;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .whatsapp-block {
    position: relative;
    padding-top: 95px;
    text-align: center;
  }
}
.footer {
  padding: 48px 0 0;
  background-color: #f1f1f1;
  color: #666666;
  font-size: 14px;
  line-height: 1.35;
  margin-bottom: 0;
  font-weight: 300;
}
.footer p {
  margin-bottom: 6px;
}
.footer p a {
  color: #666666;
  text-decoration: underline;
}
.footer p a:hover {
  color: #333333;
}
.footer__line {
  margin-bottom: 48px;
}
.footer__botline {
  padding: 20px 0 18px;
  background-color: rgba(0, 0, 0, 0.08);
}
.footer-title {
  font-size: 18px;
  color: #666666;
  margin-bottom: 16px!important;
  font-weight: 400;
}
.footer-countries {
  display: inline-block;
  margin-right: 43px;
  padding: 0;
}
.footer-countries:last-child {
  margin: 0;
}
.footer-countries__item {
  margin-bottom: 6px;
}
.footer-countries__link {
  line-height: 1.35;
  font-size: 16px;
  color: #666666;
  font-weight: 300;
}
.footer-countries__link:hover {
  color: #333333;
}
.footer-nav__item {
  margin-bottom: 6px;
}
.footer-nav__item:last-child {
  margin-bottom: 0;
}
.footer-nav__link {
  line-height: 1.35;
  font-size: 16px;
  color: #666666;
  font-weight: 300;
}
.footer-nav__link:hover {
  color: #333333;
}
.footer-social__item {
  display: inline-block;
  margin-right: 15px;
  color: #4F4F4F;
  transition: all 0.3s ease;
}
.footer-social__item:hover {
  color: #1c1c1c;
}
.footer-social__icon {
  font-size: 32px;
  line-height: 32px;
}
.footer-phone {
  color: #4F4F4F;
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  transition: all 0.3s ease;
  margin-bottom: 13px;
  display: inline-block;
}
.footer-phone:hover {
  color: #1c1c1c;
}
.footer-langs__item {
  margin: 0 0 7px;
}
.footer-langs__item img {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.footer-langs__item a {
  font-size: 14px;
  color: #666666;
  transition: all 0.3s ease;
}
.footer-langs__item a:hover {
  color: #333333;
}
.footer-contact-img {
  width: 18px;
  margin-right: 8px;
}
.footer__payment-system {
  text-align: center;
}
.footer__payment-system img {
  height: 40px;
  margin: 8px 18px;
  display: inline-block;
}
.footer .copyright {
  color: #333;
  font-size: 14px;
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .footer {
    position: relative;
    padding-top: 95px;
    text-align: center;
  }
  .footer__line .row > div {
    margin: 0 0 35px;
    position: static;
  }
  .footer__line:last-child > .row > div:last-child {
    margin: 0;
  }
  .footer-countries {
    text-align: left;
    padding: 0 10px;
    margin: 0;
  }
  .footer-countries__item {
    margin: 0 0 5px;
  }
  .footer-title {
    margin: 0 0 20px;
  }
  .footer-langs {
    display: block;
    width: 100%;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
  .footer-langs__item {
    display: inline-block;
    margin: 0 20px;
  }
}
.popup {
  position: relative;
  max-width: 432px;
  margin: 20px auto;
}
.popup .popup-nav-pills {
  margin: 0!important;
}
.popup .popup-nav-pills .nav-popup-link {
  border-radius: 20px 20px 0 0;
  border: 0;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  background-color: #BDBDBD;
  padding: 14px 60px;
  display: block;
}
.popup .popup-nav-pills .nav-popup-link.active {
  background-color: #fff;
  color: #333;
}
.popup .popup-nav-pills .nav-popup-link.active:hover {
  background-color: #fff;
  border-color: #333;
}
.popup .tab-content {
  background-color: #fff;
  padding: 35px 55px 40px;
  border-radius: 0 10px 10px 10px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
}
.popup#signup-popup .popup__body {
  background-color: #fff;
  padding: 35px 50px 40px;
  border-radius: 10px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
}
.popup-title {
  font-weight: 300;
  font-size: 36px;
  margin: 0 0 35px;
  text-align: center;
  font-weight: 400;
}
.popup .text-title {
  margin: 0 0 30px;
  max-width: 100%;
}
.popup-social {
  display: block;
  text-align: center;
  margin: 0 0 20px;
}
.popup-social__item {
  display: inline-block;
  margin: 0 13px;
}
.popup-social__icon {
  display: inline-block;
  font-size: 60px;
  height: 70px;
  color: #000;
  transition: all 0.3s ease;
}
.popup-social__icon.icon-facebook {
  color: #3b5998;
}
.popup-social__icon.icon-google-plus {
  color: #dd4b39;
}
.popup-social__icon:hover {
  opacity: .8;
}
.popup .form-control {
  text-align: center;
}
.popup .form-group {
  margin: 0 0 25px;
}
.popup-form__tip {
  font-size: 18px;
  font-weight: 300;
  font-style: italic;
  margin: 25px 0 0;
}
.popup-form__tip a {
  border-bottom: 1px solid #00B0FE;
}
.popup-form__tip a:hover {
  border-bottom-color: #65d0ff;
}
.popup-upload {
  margin: 20px 0 40px;
}
.popup-upload__or {
  padding: 0 25px;
  font-size: 24px;
}
.popup-upload--or {
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-upload-subtitle {
  font-size: 24px;
  line-height: 1.4;
  font-weight: 300;
  margin-bottom: 30px;
}
.popup-upload-subtitle span {
  margin-left: 15px;
}
.popup-upload-btns {
  margin-bottom: 70px;
}
.popup-upload-btns .form {
  display: inline-block;
}
.popup-example {
  text-align: center;
}
.popup-example img {
  max-width: 100%;
}
.popup-hr {
  position: relative;
  text-align: center;
  margin: 40px 0;
}
.popup-hr:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
  background: #BDBDBD;
}
.popup-hr span {
  position: relative;
  z-index: 2;
  padding: 0 30px;
  display: inline-block;
  background: #fff;
}
.popup-close {
  display: inline-block;
  line-height: 0;
  font-size: 52px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}
.popup-close:hover {
  opacity: 0.9;
}
.popup--questionnaire {
  max-width: 990px;
}
.popup--questionnaire .popup__body {
  background-color: #fff;
  padding: 35px 55px 40px;
  border-radius: 10px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
}
.popup--questionnaire .popup__body .container {
  max-width: none!important;
}
.popup--questionnaire .popup__body .h3 {
  margin: 10px 0;
  color: #000;
  font-weight: 400;
  font-size: 18px;
}
.popup--questionnaire .popup__body .small-text {
  font-size: 14px;
  line-height: 1.6;
}
.popup--questionnaire .popup__header {
  background-color: #00B0FE;
  padding: 23px 10px;
  border-radius: 10px 10px 0 0;
  position: relative;
}
.popup--questionnaire .popup__header .popup-close {
  position: absolute;
  right: 25px;
  top: 50%;
  margin-top: -26px;
}
.popup--questionnaire .popup__header .popup-title {
  margin-bottom: 0;
  color: #fff;
  font-size: 22px;
}
.popup--questionnaire .popup__header + .popup__body {
  border-radius: 0 0 10px 10px;
}
.popup .popup-get-from-profile {
  margin: 20px 0 40px;
}
.popup .popup-get-from-profile .pupup-image {
  border: 1px solid #cccccc;
  border-radius: 5px;
  max-width: 200px;
  margin-right: 25px;
}
.popup--howto-popup {
  max-width: 600px !important;
}
.popup--howto-popup .popup__body {
  background-color: #fff;
  padding: 35px 55px 40px;
  border-radius: 10px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  text-align: center;
  font-size: 13px;
}
.popup--howto-popup .popup__body .container {
  max-width: none!important;
}
.popup--howto-popup .popup__body h3 {
  margin: 10px 0 40px;
  color: #333333;
  font-weight: 400;
  font-size: 24px;
}
.popup--howto-popup ul {
  margin-bottom: 30px;
}
.popup--howto-popup ul li {
  margin-bottom: 18px;
  text-align: left;
  align-items: center;
}
.popup--howto-popup ul li .col-img {
  max-width: 40px;
  padding: 0;
}
.popup--howto-popup ul li img {
  height: 28px;
}
.radio-input {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: lighter;
}
@media (max-width: 768px) {
  .radio-input .input-group {
    justify-content: center;
    margin: 10px 0 40px;
  }
}
@media (min-width: 768px) {
  .radio-input .form__row {
    float: left;
    width: 500px;
    margin-right: 30px;
  }
  .radio-input .radio-group {
    text-align: center;
    float: left;
    width: auto !important;
  }
  .radio-input .form-group {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.cleara:after {
  content: "";
  display: table;
  clear: both;
}
*:focus {
  outline: none;
}
button,
button:focus,
button:active {
  outline: none !important;
}
/* general style*/
html {
  height: 100%;
}
body {
  font: 400 16px/1.38 'Open Sans', sans-serif;
  width: 100%;
  background: #fff;
  color: #333;
  height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
  min-width: 320px;
}
a {
  outline: none;
  text-decoration: none;
  color: #00B0FE;
  transition: all 0.3s ease;
}
a:hover {
  text-decoration: none;
  color: #65d0ff;
}
p {
  margin: 0 0 10px;
}
ul {
  margin: 0;
  list-style: none;
  padding: 0;
}
img {
  max-width: 100%;
  outline: none;
}
.relative {
  position: relative;
}
.trn {
  transition: all 0.3s ease;
}
.h1 {
  font-weight: 400;
  font-size: 36px;
  margin: 0 0 10px;
  text-align: center;
  line-height: 1.4;
}
.h1--xl {
  font-size: 72px;
}
.h2 {
  font-weight: 300;
  font-size: 36px;
  margin: 0 0 55px;
  text-align: center;
}
.h3 {
  font-weight: 300;
  font-size: 24px;
  margin: 0 0 40px;
}
.h3__title {
  margin-top: 40px;
}
.h4 {
  font-weight: 300;
  font-size: 18px;
  line-height: 1.4;
  margin: 0;
}
.text-title {
  max-width: 730px;
  margin: 0 auto;
  line-height: 1.65;
  color: #666666;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 55px;
  text-align: center;
}
.big-text-title {
  max-width: 650px;
  margin: 0 auto;
  line-height: 1.38;
  color: #666666;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 55px;
  text-align: center;
}
.section {
  margin: 85px 0;
}
.work-list {
  margin-bottom: 60px;
}
.work-list__item {
  text-align: center;
  position: relative;
}
.work-list__item:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.work-list__item:before {
  content: "\E901";
}
.work-list__item:before {
  position: absolute;
  right: -24px;
  color: #00B0FE;
  top: 26px;
}
.work-list .row > div:last-child .work-list__item:before {
  display: none;
}
.work-list__icon {
  font-size: 60px;
  height: 70px;
  color: #00B0FE;
  display: inline-block;
  margin-bottom: 5px;
}
.work-list__title {
  font-size: 24px;
  font-weight: 300;
  color: #000;
  margin: 0 0 20px;
}
.work-list__text {
  color: #666666;
  font-size: 16px;
  font-weight: 300;
}
.container-small {
  max-width: 960px;
  padding: 0 30px;
}
.country > .row {
  margin-left: 0;
  margin-right: 0;
}
.country__item {
  display: block;
  position: relative;
  height: 210px;
  margin: 0 -15px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media screen and (min-width: 1200px) {
  .country__item {
    height: 250px;
  }
}
.country__item-flag {
  position: absolute;
  top: -10px;
  left: 0;
  width: 58px;
  height: 58px;
  display: block;
  z-index: 5;
}
.country__item-text {
  z-index: 6;
  color: #fff;
  text-align: center;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  font-size: 24px;
}
.country__item-text p {
  margin: 0;
}
.country__item-text p:last-child {
  white-space: nowrap;
}
.country__item-title {
  font-size: 36px;
}
.country__item:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 3;
  transition: all 0.3s ease;
}
.country__item:hover:after {
  background-color: #00B0FE;
  opacity: .4;
}
.country-no-margin {
  margin-bottom: 0;
}
.icon-list__item {
  display: flex;
  margin: 0 0 50px;
}
.icon-list__left {
  flex-shrink: 0;
  width: 85px;
}
.icon-list__right {
  flex-grow: 1;
}
.icon-list__right i {
  font-size: 16px;
  color: #00B0FE !important;
}
.icon-list__icon {
  display: block;
  color: #333;
  font-size: 48px;
  line-height: 48px;
}
.icon-list__title {
  font-weight: 300;
  font-size: 24px;
  color: #333;
}
.icon-list__title_link {
  font-weight: 300;
  font-size: 24px;
  color: #00B0FE;
}
.icon-list__text {
  font-weight: 300;
  font-size: 16px;
  color: #4a4a4a;
}
.btn-outline-primary {
  color: #00B0FE;
  border-color: #00B0FE;
  cursor: pointer;
  padding: 10px 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #00B0FE;
  border-color: #00B0FE;
}
.faq-accordion {
  font-size: 18px;
  color: #000;
  max-width: 800px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .faq-accordion {
    padding: 0 20px;
  }
}
.faq-accordion .h3 {
  margin-top: 80px;
}
.faq-accordion .h3:first-child {
  margin-top: 0;
}
.faq-accordion__item {
  padding: 0 0 40px;
}
.faq-accordion__topline {
  position: relative;
  display: inline-block;
  font-size: 22px;
  color: #000;
  margin: 0 0 10px;
  padding-left: 38px;
  transition: all 0.3s ease;
}
.faq-accordion__topline:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.faq-accordion__topline:before {
  content: "\E90C";
}
.faq-accordion__topline:before {
  position: absolute;
  top: 4px;
  left: 0;
  color: #00B0FE;
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
}
.faq-accordion__topline:hover {
  color: #333333;
}
@media screen and (max-width: 767px) {
  .faq-accordion__topline {
    font-size: 18px;
  }
}
.faq-accordion__panel {
  font-weight: lighter;
  margin-left: 40px;
  font-size: 16px;
}
.faq-accordion__panel p {
  margin: 0;
}
.faq-accordion__icon {
  color: #00B0FE;
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}
.featurette-divider {
  margin: 0 0 50px;
}
.text-divider {
  margin: 60px 0;
}
.services-nav {
  display: flex;
  align-items: center;
  background-color: #FCFCFC;
}
.services-nav__link {
  position: relative;
  display: block;
  text-align: center;
  margin: 0 -15px;
  background-color: #FCFCFC;
  color: #333;
  padding: 30px 5px 20px;
}
.services-nav__link:after {
  content: '';
  position: absolute;
  display: block;
  width: 1px;
  height: auto;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #c9c9c9;
}
.services-nav__link.active {
  background-color: #00B0FE !important;
  color: #fff;
}
.services-nav__link.active .link-text {
  font-weight: 600;
}
.services-nav__link.active:after {
  background-color: transparent;
}
.services-nav__link.active:hover {
  color: #fff;
  opacity: .85;
}
.services-nav__link .link-icon {
  display: block;
  font-size: 48px;
  height: 48px;
  margin: 0 0 10px;
}
.services-nav__link .link-text {
  font-size: 24px;
  font-weight: 300;
}
.services-nav__link:hover {
  background-color: #f4f4f4;
  color: #333;
}
.services-nav > div:last-child .services-nav__link:after {
  display: none;
}
.news {
  width: 80%;
  margin: 0 auto;
}
.news__item {
  margin: 0 0 40px;
  display: flex;
  font-weight: lighter;
}
.news__item:last-child {
  margin: 0;
}
.news__item-left {
  flex-shrink: 0;
  width: 170px;
}
.news__item-right {
  flex-grow: 1;
}
.news__item-date {
  color: #4F4F4F;
  font-size: 24px;
  font-weight: 300;
}
.news__item-title {
  color: #000;
  font-size: 24px;
  font-weight: 300;
  margin: 0 0 20px;
}
.news__item-text {
  font-weight: lighter;
}
.news__item p1 {
  font-weight: lighter;
}
.news__item ul {
  list-style: circle;
}
.news__item ul li {
  margin-left: 20px;
  margin-bottom: 6px;
}
.check-list {
  width: 80%;
  margin: 0 auto;
}
.check-list__item {
  margin: 0 0 45px;
  padding-left: 90px;
  position: relative;
  color: #333;
  font-size: 18px;
  font-weight: 300;
}
.check-list__item:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.check-list__item:before {
  content: "\E91A";
}
.check-list__item:before {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 30px;
  color: #00B0FE;
}
.check-list__item:last-child {
  margin: 0;
}
.tab-pane {
  margin: 0 auto;
}
.tab-pane h1,
.tab-pane h2,
.tab-pane h3,
.tab-pane h4,
.tab-pane h5,
.tab-pane h6 {
  margin-bottom: 40px;
  font-weight: 300;
}
.tab-pane h4 {
  line-height: 1.38;
  color: #666666;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 55px;
}
.tab-pane h6 {
  font-size: 18px;
  font-weight: 300;
  color: #666666;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .tab-pane h6 {
    text-align: center;
    margin-bottom: 20px;
  }
}
.tab-pane table {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .tab-pane table {
    width: 100%;
  }
}
.tab-pane table td {
  font-size: 18px;
  font-weight: 300;
  color: #666666;
  margin: 0;
}
@media screen and (max-width: 767px) {
  .tab-pane table td {
    margin-bottom: 20px;
  }
}
.tab-pane table:after {
  content: "";
  display: block;
  width: 1px;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -15px;
  background-color: #f1f1f1;
}
.tab-pane table:last-child:after {
  display: none;
}
.tab-pane ul {
  list-style: none;
}
.tab-pane ul li {
  margin: 0 0 45px;
  padding-left: 60px;
  position: relative;
  color: #333;
  font-size: 18px;
  font-weight: 300;
}
.tab-pane ul li:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tab-pane ul li:before {
  content: "\E91A";
}
.tab-pane ul li:before {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 30px;
  color: #00B0FE;
}
.tab-pane ul li:last-child {
  margin: 0;
}
.prices__item {
  text-align: center;
  position: relative;
  margin-bottom: 10px;
}
.prices__item-title {
  font-size: 18px;
  font-weight: 300;
  color: #666666;
  margin: 0;
  text-align: right;
}
@media screen and (max-width: 767px) {
  .prices__item-title {
    text-align: center;
    margin-bottom: 20px;
  }
}
.prices__item:after {
  content: "";
  display: block;
  width: 1px;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -15px;
  background-color: #f1f1f1;
}
.prices__item:last-child:after {
  display: none;
}
.prices__list {
  text-align: right;
}
.prices__list-item {
  margin: 0 0 5px;
  font-size: 22px;
  color: #333;
  font-weight: 300;
  font-style: italic;
}
.prices__list-item span {
  font-size: 26px;
  font-style: normal;
  margin-left: 10px;
  white-space: nowrap;
}
.prices__list-item:last-child {
  margin: 0;
}
.prices .price-text {
  font-weight: lighter;
  color: #333;
  font-size: 18px;
  text-align: left;
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .prices .price-text {
    text-align: center;
  }
}
.prices .price-link {
  font-weight: 300;
  font-style: italic;
  font-size: 14px;
}
.form .form-control {
  border-color: #BDBDBD;
  border-width: 0.5px;
  color: #333;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.38;
  padding: 10px 14px;
  border-radius: 2px;
  transition: all 0.3s ease;
}
.form .form-control.error {
  border-color: #EB5757;
}
.form .form-control.error:focus {
  border-color: #666666;
}
.form .form-control:focus {
  border-color: #BDBDBD;
}
.form .selectize-control.form-control {
  padding: 0;
}
.form .form-group {
  margin: 0 0 25px;
}
.form label,
.form .form__row {
  font-size: 18px;
  margin-bottom: .5rem;
  font-weight: 300;
}
.form label p,
.form .form__row p {
  margin-bottom: 0;
}
.form-error {
  background: #EB5757;
  padding: 20px;
  margin: 0 -55px 25px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}
.form ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}
.form ul li {
  display: inline;
}
.delivery_radio_input .form-control {
  border-color: #BDBDBD;
  border-width: 0.5px;
  color: #333;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.38;
  padding: 10px 14px;
  border-radius: 2px;
  transition: all 0.3s ease;
}
.delivery_radio_input .form-control.error {
  border-color: #EB5757;
}
.delivery_radio_input .form-control.error:focus {
  border-color: #666666;
}
.delivery_radio_input .form-control:focus {
  border-color: #BDBDBD;
}
.delivery_radio_input .selectize-control.form-control {
  padding: 0;
}
.delivery_radio_input .form-group {
  margin: 0 0 25px;
}
.delivery_radio_input label,
.delivery_radio_input .form__row {
  font-size: 18px;
  margin-bottom: .5rem;
  font-weight: 300;
}
.delivery_radio_input label p,
.delivery_radio_input .form__row p {
  margin-bottom: 0;
}
.delivery_radio_input-error {
  background: #EB5757;
  padding: 20px;
  margin: 0 -55px 25px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}
.delivery_radio_input ul {
  max-width: 80%;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}
.delivery_radio_input ul li {
  display: inline;
  float: left;
}
.delivery_radio_input .radio-group,
.delivery_radio_input .input-group {
  float: right!important;
  width: auto;
}
.contact-form {
  max-width: 450px;
  margin: 0 auto;
}
.contact-nav-pills {
  margin: 0 0 25px;
}
.contact-nav-pills .contact-nav-item {
  flex-grow: 1;
  width: 50%;
}
.contact-nav-pills .contact-nav-item:first-child .contact-nav-link {
  border-right: 0;
  border-radius: 5px 0 0 5px;
}
.contact-nav-pills .contact-nav-item:last-child .contact-nav-link {
  border-left: 0;
  border-radius: 0 5px 5px 0;
}
.contact-nav-pills .contact-nav-link {
  border-radius: 5px;
  border: 0.5px solid #BDBDBD;
  text-align: center;
  font-size: 18px;
  line-height: 1.38;
  font-weight: 300;
  color: #333;
  padding: 10px 5px;
  display: block;
}
.contact-nav-pills .contact-nav-link.active {
  background-color: #00B0FE;
  border-color: #00B0FE;
  color: #fff;
  font-weight: 600;
}
.contact-nav-pills .contact-nav-link.active:hover {
  background-color: #32c0ff;
  border-color: #32c0ff;
}
.form-group-selectbox {
  margin-bottom: 24px;
}
.form-select.selectize-control.single .selectize-input {
  border-color: #BDBDBD;
  padding: 9px 55px 12px 14px!important;
  font-size: 18px;
  color: #333;
  font-weight: 300;
  border-radius: 2px;
  display: block;
}
.form-select.selectize-control.single .selectize-input:before {
  display: inline-block;
  font-family: 'icon font';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.form-select.selectize-control.single .selectize-input:before {
  content: "\E90C";
}
.form-select.selectize-control.single .selectize-input:after {
  display: none;
}
.form-select.selectize-control.single .selectize-input:before {
  position: absolute;
  top: 11px!important;
  right: 25px!important;
  color: #BDBDBD;
  font-size: 18px;
  line-height: 1.38;
  left: auto;
  height: 24px;
  background: none;
}
.form-select.selectize-control.single .selectize-input.focus {
  box-shadow: none;
}
.form-select.selectize-control.single .selectize-input.dropdown-active {
  border-radius: 2px 2px 0 0;
  border-bottom-color: transparent;
}
.form-select.selectize-control.single .selectize-input.dropdown-active:before {
  transform: rotate(180deg);
}
.form-select.selectize-control.single .selectize-input .green-text {
  margin-left: 10px;
}
.form-select .selectize-dropdown {
  padding: 0;
}
.form-select .selectize-dropdown,
.form-select .selectize-dropdown.form-control {
  margin: -2px 0;
  border-radius: 0 0 2px 2px;
  border-color: #BDBDBD;
  border-top: none;
  box-shadow: none;
}
.form-select .selectize-dropdown-content {
  padding: 0;
}
.form-select .selectize-dropdown-content .option {
  padding: 10px 14px;
  font-size: 18px;
  color: #333;
  font-weight: 300;
}
.form-select .selectize-dropdown-content .option.active {
  background: rgba(0, 0, 0, 0.05);
}
.form-select .selectize-dropdown-content .option .green-text {
  margin-left: 10px;
}
.form-select--big {
  max-width: 460px;
  margin: 0 auto 30px;
}
.form-select--big.selectize-control.single .selectize-input {
  font-size: 18px;
  padding: 13px 55px 15px 25px!important;
}
.form-select--big.selectize-control.single .selectize-input:before {
  top: 13px!important;
}
.form-select--big .selectize-dropdown-content .option {
  padding: 10px 20px;
}
.form-control:focus,
.btn:focus {
  border-color: inherit;
  box-shadow: none;
}
.btn-primary {
  background-color: #00B0FE;
  border-color: #00B0FE;
  text-transform: uppercase;
  padding: 10px 50px;
  font-weight: 600;
  cursor: pointer;
}
.btn-primary:hover {
  background-color: #32c0ff;
  border-color: #32c0ff;
}
.btn-clear {
  border-color: #9F9F9F;
  background: transparent;
  text-transform: uppercase;
  padding: 10px 50px;
  font-weight: 600;
  cursor: pointer;
  color: #4F4F4F;
}
.btn-clear:hover {
  background-color: #ececec;
}
.btn-big-icon {
  display: inline-flex;
  align-items: center;
  padding: 15px 30px;
  border-radius: 10px;
  border: 1px solid #00B0FE;
  color: #000;
}
.btn-big-icon:hover {
  color: #00B0FE;
}
.btn-big-icon--green {
  border: none;
  background: #6FCF97;
  color: #fff !important;
}
.btn-big-icon--green:hover {
  background: #82d5a5;
  color: #fff !important;
}
.btn-big-icon--green .btn-big-icon__icon {
  color: #fff;
}
.btn-big-icon--red {
  border: none;
  background: #ff4b5b;
  color: #fff !important;
}
.btn-big-icon--red:hover {
  background: #ff6572;
  color: #fff !important;
}
.btn-big-icon--red .btn-big-icon__icon {
  color: #fff;
}
.btn-big-icon__icon {
  color: #00B0FE;
  font-size: 49px;
  line-height: 0;
  padding-right: 30px;
  position: relative;
  top: 2px;
}
._active .btn-big-icon__icon {
  color: #fff !important;
}
.btn-big-icon__text {
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
}
.btn-big-icon._active {
  background: #00B0FE;
  color: #fff !important;
}
.map {
  position: relative;
}
.map-container {
  position: relative;
  display: block;
  height: 625px;
}
.map-container:after {
  content: '';
  position: absolute;
  width: 50%;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
}
.map .container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.map__info {
  position: absolute;
  right: 15px;
  top: 0;
  width: 50%;
  height: 625px;
  padding: 200px 50px 50px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}
.map__info a {
  color: #fff !important;
}
.map__info-email {
  border-bottom: 1px solid #fff;
  white-space: nowrap;
  transition: all 0.3s ease;
}
.map__info-email:hover {
  border-color: transparent;
}
.map__info-phone {
  white-space: nowrap;
  font-weight: 300;
}
.map__info-title {
  font-weight: 600;
  font-size: 20px;
  margin: 0 auto 40px;
  max-width: 300px;
}
.map__info-text {
  margin: 0 0 25px;
}
.map__info-text:last-child {
  margin: 0;
}
.mfp-bg {
  background: rgba(0, 0, 0, 0.47);
  opacity: 1;
}
.breadcrumbs {
  display: flex;
  align-items: center;
}
.breadcrumbs-wr {
  overflow: hidden;
}
.breadcrumbs__link {
  display: block;
  text-align: center;
  margin: 0 -15px;
  background-color: #60CEFE;
  color: #fff;
  padding: 23px 5px 23px 35px;
  font-size: 18px;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
}
.breadcrumbs__link:hover {
  color: #fff;
  background-color: #79d6fe;
}
.breadcrumbs__link:hover:after {
  border-color: transparent transparent transparent #79d6fe;
}
.breadcrumbs__link:after {
  content: "";
  position: absolute;
  right: -30px;
  top: -4px;
  border: 39px solid transparent;
  border-color: transparent transparent transparent #60CEFE;
  border-right: 0;
  border-left-width: 30px;
  z-index: 1;
}
.breadcrumbs__link:before {
  content: "";
  position: absolute;
  right: -30px;
  top: -4px;
  border: 39px solid transparent;
  border-color: transparent transparent transparent #fff;
  border-right: 0;
  border-left-width: 30px;
  z-index: 1;
  margin-right: -2px;
}
.breadcrumbs__link i {
  font-style: normal;
}
._active .breadcrumbs__link {
  background: #00B0FE;
  color: #fff;
}
._active .breadcrumbs__link:after {
  border-color: transparent transparent transparent #00B0FE;
}
._active .breadcrumbs__link:before {
  display: none;
}
.breadcrumbs__item._active ~ li .breadcrumbs__link {
  background-color: #e7e7e7;
  color: #333;
}
.breadcrumbs__item._active ~ li .breadcrumbs__link:after {
  border-color: transparent transparent transparent #e7e7e7;
}
.breadcrumbs__item._active ~ li .breadcrumbs__link:before {
  border-color: transparent transparent transparent #D0D0D0;
}
.breadcrumbs__item:last-child .breadcrumbs__link:after,
.breadcrumbs__item:last-child .breadcrumbs__link:before {
  display: none;
}
.tooltip-wr {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  height: 16px;
  width: 16px;
  line-height: 16px;
  text-align: center;
  background: #BDBDBD;
  border-radius: 50%;
  color: #fff;
  margin: -1px 0 0 8px;
  vertical-align: middle;
}
.tooltip-inner {
  max-width: 350px;
}
.date-input {
  position: relative;
  background: #fff;
}
.date-input .datepicker {
  padding-right: 60px;
  background: transparent;
}
.date-input .input-group-addon {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 16px 0 0;
  background: none;
  border: none;
  color: #BDBDBD;
  z-index: 1;
  font-size: 30px;
  height: 45px;
  line-height: 45px;
  text-align: center;
}
.date-input .input-group-addon span {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  position: relative;
  top: -1px;
}
.radio {
  display: inline-block;
  position: relative;
  padding: 10px 20px 10px;
  font-size: 14px;
  color: #4F4F4F;
  margin: 0!important;
  border: 1px solid #BDBDBD;
  border-radius: 2px 0 0 2px;
  border-right-width: 0;
  cursor: pointer;
}
.radio:last-child {
  border-right-width: 1px;
  border-radius: 0 2px 2px 0;
}
.radio__bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.radio__input {
  display: none;
  opacity: 0;
}
.radio__input:checked + .radio__bg {
  background: #00B0FE;
}
.radio--green .radio__input:checked + .radio__bg {
  background: #6FCF97;
}
.radio__input:checked + .radio__bg + .radio__text {
  color: #fff;
}
.radio__text {
  position: relative;
  z-index: 1;
  font-weight: 300;
}
.form-last-row {
  margin-top: 20px;
}
.form-last-row .btn {
  font-size: 18px;
  line-height: 1.3;
  font-weight: normal;
  padding: 10px 55px;
  text-transform: none;
  margin: 10px 20px;
}
.form-group.error .errorlist,
.form-group.error .errorlist {
  font-weight: lighter;
  font-style: italic;
  margin-top: 2px;
  color: #EB5757;
}
.form-group.error .form-control {
  border-color: #EB5757 !important;
  background: #fce9e9 !important;
}
.form-group.error .date-input {
  background: #fce9e9 !important;
}
.form-group.error .date-input .form-control {
  background: transparent!important;
}
.form-group.error .radio {
  border-color: #EB5757 !important;
  background: #fce9e9 !important;
}
.form-group.error .form-select.selectize-control.single {
  border-color: none!important;
  background: none!important;
}
.form-group.error .form-select.selectize-control.single .selectize-input {
  border-color: #EB5757 !important;
  background: #fce9e9 !important;
}
.form-group.error .choices__inner {
  border-color: #EB5757 !important;
  background: #fce9e9 !important;
}
.form-group.error .choices__input {
  background: transparent;
}
.row-subtitle {
  margin: 60px 0 40px;
}
.row-subtitle .h3 {
  margin-bottom: 10px;
}
.row-subtitle p {
  font-weight: 300;
  font-size: 18px;
}
.btn-right {
  position: relative;
}
.btn-right__btn {
  position: absolute;
  left: 100%;
  margin-left: 50px;
  top: 50%;
  transform: translateY(-50%);
}
.btn-right--ib {
  display: inline-block;
}
.btn-with-icon {
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
}
.btn-with-icon--red {
  color: #EB5757;
}
.btn-with-icon--red:hover {
  color: #f08585;
}
.btn-with-icon--green {
  color: #00BA97;
}
.btn-with-icon--green:hover {
  color: #00d3ac;
}
.btn-with-icon__icon {
  line-height: 0;
  font-size: 28px;
}
.btn-with-icon__text {
  font-size: 18px;
  font-weight: 300;
  margin-left: 20px;
}
.step-block {
  margin: 30px 0;
  font-size: 18px;
}
.step-block:first-child {
  margin: 0 0 30px;
}
.step-block .h2 {
  font-weight: 400;
  margin-bottom: 20px;
}
.step-block .h2 + .h3 {
  margin-bottom: 15px;
  font-weight: 300;
}
.step-block .h3 {
  font-weight: 400;
  margin-bottom: 20px;
  text-align: center;
}
.step-block p {
  font-weight: 300;
}
.step-block--price {
  border-top: 1px solid #ccc;
  padding-top: 40px;
  margin-top: 60px;
}
.step-block--price td {
  font-size: 20px !important;
}
.step-block .warning {
  font-size: 14px !important;
  text-align: center;
  margin-top: 5px;
}
.step-list {
  font-weight: lighter;
  width: 100%;
}
.step-list td {
  width: 50%;
  padding: 5px 10px;
  font-size: 14px;
  text-align: right;
}
.step-list td p {
  margin: 0;
}
.step-list td.value {
  text-align: left;
  font-weight: 300;
}
.step-list td.value .icon-tick {
  color: #00B0FE;
}
.step-list td.value .icon-tick--green {
  color: #00BA97;
}
.step-list td.value.green-text {
  color: #00BA97;
}
.step-list td.value .blue-text {
  color: #00B0FE;
}
.green-text {
  color: #00BA97;
}
.step-finish {
  font-size: 80px;
  line-height: 0;
  color: #6AC259;
}
.document {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 180px;
  margin: 25px 10px;
  padding: 0 30px;
  border: 1px solid #828282;
  border-radius: 10px;
  text-align: center;
}
.document__icon {
  display: none;
  font-size: 26px;
  line-height: 0;
  margin-bottom: 10px;
}
.document__help {
  font-style: italic;
  color: #00BA97;
  margin-top: 15px;
  font-weight: 300;
}
.document .h3,
.document .h4 {
  display: block;
  color: #333;
  font-size: 16px;
  margin-bottom: 0;
}
.document:hover {
  background: #f1f1f1;
}
.document--ready {
  background: #00B0FE !important;
  color: #fff;
  border-color: #00B0FE;
}
.document--ready .h3 {
  font-weight: 400;
  color: #fff;
}
.document--ready .document__icon {
  display: block;
}
.document--ready .document__help {
  display: none;
}
.document--error {
  border-color: #EB5757;
}
.document--error .h3 {
  color: #EB5757;
}
.datepicker-days {
  font-size: 12px;
}
.datepicker-days .disabled {
  color: rgba(255, 0, 0, 0.2) !important;
  text-decoration: line-through;
}
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active:active {
  background: #00B0FE;
  border-color: #00B0FE;
}
.datepicker table tr td.active.active:hover,
.datepicker table tr td.active.highlighted.active:hover,
.datepicker table tr td.active.highlighted:active:hover,
.datepicker table tr td.active:active:hover {
  background: #009ee5;
  border-color: #009ee5;
}
#info-table {
  font-size: 13px;
}
#info-table td:first-child {
  width: 15%;
}
#info-table td:nth-child(2) {
  width: 25%;
}
#info-table td:nth-child(3) {
  width: 30%;
}
#info-table td:nth-child(4) {
  width: 30%;
}
#info-table ul {
  list-style: circle;
}
#info-table ul li {
  padding: 5px;
  overflow-wrap: normal;
}
.selectize-dropdown [data-selectable] .highlight {
  background: #98dfff;
}
@media screen and (max-width: 991px) {
  .breadcrumbs__item:first-child .breadcrumbs__link {
    padding-left: 20px;
  }
  .breadcrumbs__link:before,
  .breadcrumbs__link:after {
    border-left-width: 24px;
    right: -24px;
  }
  .breadcrumbs__link {
    padding-left: 30px;
  }
  .popup--questionnaire .popup__body {
    padding: 25px 35px 30px;
  }
}
@media screen and (max-width: 767px) {
  .h2 {
    font-size: 32px;
  }
  .text-title {
    margin: 0 0 35px;
    font-size: 16px;
  }
  .section {
    margin: 65px 0;
  }
  .country__item {
    height: 190px;
  }
  .prices__item {
    border-bottom: 1px solid #f1f1f1;
    padding: 0 0 30px;
    margin: 0 0 30px;
  }
  .prices__item:first-child {
    padding-right: 0;
  }
  .prices__item:after {
    display: none;
  }
  .prices__list {
    text-align: center;
  }
  .prices .price-text {
    margin: 0 0 5px;
  }
  .prices .row > div:last-child .prices__item {
    padding: 0;
    margin: 0;
    border: 0;
  }
  .check-list {
    width: 100%;
    margin: 0;
  }
  .check-list__item {
    margin: 0 0 25px;
    padding-left: 40px;
    font-size: 14px;
  }
  .check-list__item:before {
    font-size: 20px;
    top: 2px;
  }
  .work-list__item {
    margin: 0 0 35px;
  }
  .work-list__item:before {
    display: none;
  }
  .icon-list__left {
    width: 70px;
  }
  .icon-list__item {
    margin: 0 0 30px;
  }
  .news {
    width: 100%;
    margin: 0;
  }
  .news__item {
    margin: 0 0 30px;
    display: block;
  }
  .news__item-left {
    width: 100%;
    text-align: center;
    margin: 0 0 10px;
  }
  .news__item-date {
    color: #4F4F4F;
    font-size: 20px;
  }
  .news__item-title {
    font-size: 22px;
    margin: 0 0 20px;
    text-align: center;
  }
  .popup .popup-nav-pills .nav-popup-link {
    padding: 14px 40px;
  }
  .popup .tab-content {
    padding: 25px 30px 35px;
  }
  .popup#signup-popup .popup__body {
    padding: 25px 30px 30px;
  }
  .popup-title {
    margin: 0 0 25px;
  }
  .popup .text-title {
    margin: 0 0 15px;
  }
  .popup-social {
    margin: 0 0 10px;
  }
  .popup .form-error {
    margin: 0 -30px 25px;
  }
  .services-nav__link {
    padding: 10px 5px;
  }
  .services-nav__link .link-text {
    font-size: 17px;
  }
  .services-nav__link .link-icon {
    font-size: 38px;
    height: 38px;
  }
  .text-divider {
    margin: 45px 0;
  }
  .h1 {
    font-size: 24px;
    margin: 0 0 50px;
  }
  .h1--xl {
    font-size: 40px;
  }
  .map {
    height: 525px;
  }
  .map-container:after {
    display: none;
  }
  .map__info {
    left: 0;
    right: 0;
    width: auto;
    padding: 35px 15px;
    height: auto;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .map__info-title,
  .map__info-text {
    margin-bottom: 15px;
  }
  .map__info-phone {
    font-size: 30px!important;
  }
  .map .container {
    left: 0;
    right: 0;
    width: auto;
    max-width: none;
    transform: none;
  }
  .breadcrumbs__link i {
    display: none;
  }
  ._active .breadcrumbs__link i {
    display: inline;
  }
  .breadcrumbs__item._active {
    flex-grow: 3;
  }
  .form-last-row {
    margin-top: 40px;
  }
  .btn-right {
    padding-right: 60px;
  }
  .popup-upload .btn-right {
    padding-right: 50px;
  }
  .btn-right__btn {
    margin-left: 0;
    left: auto;
    right: 5px;
  }
  .btn-with-icon__text {
    display: none;
  }
  .row-subtitle {
    margin-top: 40px;
  }
  .step-block {
    margin: 35px 0;
  }
  .step-block:first-child {
    margin: 0 0 35px;
  }
  .popup--questionnaire .popup__header {
    padding: 13px 55px 16px 10px;
  }
  .popup--questionnaire .popup__header .popup-title {
    font-size: 24px;
  }
  .popup--questionnaire .popup__header .popup-close {
    font-size: 32px;
    margin-top: -16px;
    right: 15px;
  }
  .popup--questionnaire .popup__body {
    padding: 25px 10px 30px;
  }
  .popup-upload {
    margin: 20px 0 30px;
  }
  .btn-big-icon {
    padding: 10px 25px;
  }
  .btn-big-icon__text {
    font-size: 20px;
  }
  .btn-big-icon__icon {
    font-size: 36px;
    padding-right: 20px;
  }
  .popup-upload--or {
    display: block;
  }
  .popup-upload--or .popup-upload__or {
    display: block;
    margin: 15px 0;
  }
  .popup-upload-btns {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 575px) {
  .services-nav {
    flex-wrap: wrap;
  }
  .services-nav .col {
    flex-basis: auto;
    width: 50%;
  }
  .services-nav__link:after {
    display: none;
  }
  .map__info {
    font-size: 20px;
  }
  .map__info-phone {
    font-size: 24px!important;
  }
  .breadcrumbs__item {
    display: none;
  }
  .breadcrumbs__item._active {
    display: block;
    flex-grow: 5;
  }
  .breadcrumbs__item._active + .breadcrumbs__item {
    display: block;
  }
  .breadcrumbs__item._prev {
    display: block;
  }
  .step-list--mob b {
    display: block;
  }
  .h2--mob {
    font-size: 26px;
  }
  .h3--mob {
    font-size: 18px;
  }
  .dropdown-lang .dropdown-menu-right {
    position: relative;
  }
}
.popup--howto-popup {
  max-width: 600px !important;
}
.popup--howto-popup .popup__body {
  background-color: #fff;
  padding: 35px 55px 40px;
  border-radius: 10px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  text-align: center;
  font-size: 13px;
}
.popup--howto-popup .popup__body .container {
  max-width: none!important;
}
.popup--howto-popup .popup__body h3 {
  margin: 10px 0 40px;
  color: #333333;
  font-weight: 400;
  font-size: 24px;
}
.popup--howto-popup .popup__body p {
  color: red;
  margin-bottom: 30px;
}
.popup--howto-popup ul {
  margin-bottom: 30px;
}
.popup--howto-popup ul li {
  margin-bottom: 18px;
  text-align: left;
  align-items: center;
}
.popup--howto-popup ul li .required:after {
  content: '*';
  color: red;
  padding-left: 5px;
}
.popup--howto-popup ul li .col-img {
  max-width: 40px;
  padding: 0;
}
.popup--howto-popup ul li img {
  height: 28px;
}
.hidden_node {
  display: none;
}
.document-upload-error {
  color: #EB5757;
}
.contact-form-sent {
  margin: 100px auto;
  max-width: 500px;
}
.contact-form-sent p {
  text-align: center;
  font-size: 24px;
  font-weight: lighter;
}
.description-page {
  font-weight: lighter;
}
.description-page .container {
  max-width: 800px!important;
}
@media screen and (max-width: 767px) {
  .description-page .container {
    padding: 0 30px;
  }
}
.description-page ul {
  list-style: circle;
  margin: 0 0 10px;
}
.description-page ul li {
  margin-left: 20px;
  margin-bottom: 6px;
}
.description-page ul li ul {
  margin-left: 20px;
  margin-top: 10px;
}
.confirm-page {
  font-size: smaller;
}
.confirm-page h4 {
  font-size: 14px;
  font-weight: bold;
}
.confirm-page table {
  margin-bottom: 30px;
}
.confirm-page ul li {
  padding: 5px;
}
.othercountries-page {
  font-weight: lighter;
}
.othercountries-page .container {
  max-width: 800px;
}
@media screen and (max-width: 767px) {
  .othercountries-page .container {
    padding: 0 30px;
  }
}
.othercountries-page table {
  width: 100%;
}
.othercountries-page table td {
  padding: 20px;
}
.othercountries-page table td:first-child {
  width: 200px;
  font-weight: normal;
  font-size: 20px;
  text-align: right;
}
.othercountries-page table tr:nth-child(even) {
  background: #fafafa;
}
.othercountries-page ul li {
  padding: 5px;
}
.route-form {
  margin-bottom: 60px;
}
.order-prices__item {
  margin-bottom: 40px;
}
.order-prices__item-title {
  font-size: 18px;
  font-weight: 300;
  color: #666666;
  margin: 0;
  text-align: right;
}
@media screen and (max-width: 767px) {
  .order-prices__item-title {
    text-align: center;
    margin-bottom: 20px;
  }
}
.order-prices__item-value {
  text-align: left;
}
@media screen and (max-width: 767px) {
  .order-prices__item-value {
    text-align: center;
    margin-bottom: 20px;
  }
}
.trip {
  margin: 0 auto;
}
.trip .text-box {
  font-weight: lighter;
  margin-bottom: 30px;
}
.trip .radio-input .form__row {
  width: auto;
}
.back_to_top {
  position: fixed;
  z-index: 1000;
  left: 20px;
  bottom: 20px;
  width: 20px;
  height: 20px;
  background: rgba(32, 35, 42, 0.1);
  border-radius: 3px;
  opacity: 0;
  cursor: pointer;
  display: none;
  -moz-transition: all 100ms ease-in-out;
  -o-transition: all 100ms ease-in-out;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}
.back_to_top:before {
  content: "\F106";
  font-family: "FontAwesome";
  position: absolute;
  line-height: 20px;
  text-align: center;
  width: 100%;
  color: #fff;
  -moz-transition: all 100ms ease-in-out;
  -o-transition: all 100ms ease-in-out;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}
.back_to_top:hover {
  right: 10px;
  bottom: 10px;
  width: 40px;
  height: 40px;
  background: rgba(32, 35, 42, 0.95);
}
.back_to_top:hover:before {
  line-height: 40px;
}
.back_to_top.show {
  display: block;
  opacity: 1;
  animation: fadeIn 200ms ease-in;
}
.choices__list--multiple .choices__item {
  background-color: #00b0fe;
  border: 1px solid #4777bb;
}
.choices__list--dropdown {
  z-index: 100;
}
.documents-list {
  text-align: center !important;
}
.col .text-box {
  margin-bottom: 50px;
}
.toggle-yes .text-box {
  margin-top: 50px;
}
.original-documents {
  text-align: center;
  font-size: 14px;
  font-weight: lighter;
}
.original-documents ul {
  list-style: none;
  font-weight: normal;
}
.original-documents .address {
  margin-top: 30px;
}
.original-documents .info {
  margin-bottom: 30px;
  color: red;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #00b0fe;
  width: 120px;
  height: 120px;
  z-index: 1000;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  overflow: auto;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-container {
  display: none;
}
.overlay {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #c8c8c8;
  opacity: 0.6;
}
.constant {
  line-height: 46px;
  padding-left: 16px;
  color: #333;
  font-size: 18px;
  font-weight: 300;
}
